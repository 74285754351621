// @flow

import type { Node } from 'react'
import React from 'react'

import Layout from '../components/layout'
import { BodyText, Heading1, Heading2, Heading4 } from '../components'

const links = [
  {
    id: 'baby-equipment-rentals',
    url: 'http://www.happybabyrentals.com',
    text: 'Baby Equipment Rentals',
    phone: '239-765-7368',
  },
  {
    id: 'vacation-rentals',
    url: 'http://www.leisureamerican.com',
    text: 'Vacation Rentals',
    phone: '239-463-3138',
  },
  {
    id: 'chamber-of-commerce',
    url: 'http://fortmyersbeach.org',
    text: 'Chamber of Commerce + Local Business Information',
  },
  {
    id: 'beach-health-center',
    url: 'http://beachhealthcenter.com',
    text: 'Beach Health Center',
    address: '6051 Estero Boulevard | Fort Myers Beach | Florida | 33931',
    phone: '239-765-6000',
  },
  {
    id: 'ft-myers-rentals',
    url: 'http://fortmyersbeachbikerentals.com',
    text: 'Bike, Paddle Board & Kayak Rentals – Fun-N-Sun Rentals',
    phone: '239-728-7564',
  },
  {
    id: 'facebook',
    url: 'http://www.facebook.com/pages/Fort-Myers-Beach-FL/Cottages-of-Paradise-Point-Ft-Myers-Beach/314688145734',
    text: 'Facebook',
  },
]

type LinkEntriesProps = {
  entries: Array<{
    id: string,
    text: string,
    url: string,
    phone?: string,
    address?: string,
  }>,
}
function LinkEntries({ entries }: LinkEntriesProps) {
  return (
    <ul className="pt-4">
      {entries.map(({ id, text, url, phone, address }) => (
        <li
          key={id}
          id={id}
          className="leading-normal mb-4 border-b border-purple-lighter border-solid pb-4"
        >
          <Heading2 className="leading-tight mb-4">{text}</Heading2>
          <div className="pl-4">
            {url && (
              <div className="mb-3">
                <Heading4 className="text-purple-lightest">Website</Heading4>
                <BodyText>
                  <a href={url} className="text-purple-lightest">
                    {text}
                  </a>
                </BodyText>
              </div>
            )}
            {phone && (
              <div className="mb-3">
                <Heading4 className="text-purple-lightest">Phone</Heading4>
                <BodyText>
                  <a href={`tel:${phone}`} className="text-purple-lightest">
                    {phone}
                  </a>
                </BodyText>
              </div>
            )}
            {address && (
              <div>
                <Heading4 className="text-purple-lightest">Address</Heading4>
                <BodyText className="text-purple-lightest">{address}</BodyText>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  )
}

function LinksPage(): Node {
  return (
    <Layout>
      <div className="bg-purple text-white">
        <div className="pt-12 pb-2 md:pt-16 px-8">
          <div className="w-full md:max-w-md md:mx-auto">
            <div className="mb-6">
              <Heading1 isTitle className="mb-5">
                Resources and Links
              </Heading1>
              <LinkEntries entries={links} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LinksPage
